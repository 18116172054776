import * as React from 'react';
import Box from '@mui/material/Box';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';

interface CheckNetworkProps {
  open: boolean;
  message: string;
  autoHideDuration?: number;
  currentStatus?: boolean;
  onClose: () => void;
}

export const CheckNetworkStatus = ({
  open = false,
  message,
  autoHideDuration,
  currentStatus,
  onClose
}: CheckNetworkProps) => {
  const [state, setState] = React.useState<SnackbarOrigin>({
    vertical: 'bottom',
    horizontal: 'center'
  });
  const { vertical, horizontal } = state;

  return (
    <Box className="network-status" sx={{}}>
      <Snackbar
        className={`${currentStatus ? 'net-online' : 'net-offline'}`}
        sx={{ mt: 2 }}
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        autoHideDuration={autoHideDuration}
        onClose={onClose}
        message={message}
        key={vertical + horizontal}
      />
    </Box>
  );
};

export default CheckNetworkStatus;
