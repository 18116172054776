import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'src/store/reducer';
import {
  deleteProductAPI,
  getProductsAPI,
  uploadFileAPI
} from '../api/ProductApi';
import { ProductDataModel } from '../model';

export interface ProductState {
  isError: boolean;
  isSuccess: boolean;
  successMsg: string;
  errorMsg: string;
  loading: boolean;
  isUpdate: boolean;
  products: ProductDataModel[];
}

const initialState: ProductState = {
  isError: false,
  isUpdate: false,
  isSuccess: true,
  successMsg: '',
  errorMsg: '',
  loading: false,
  products: []
};

export const selectorProductState = ({ productData }: RootState) => ({
  isError: productData?.isError,
  isUpdate: productData?.isUpdate,
  isSuccess: productData?.isSuccess,
  successMsg: productData?.successMsg,
  errorMsg: productData?.errorMsg,
  loading: productData?.loading,
  products: productData?.products
});

// services
export const getProducts = createAsyncThunk(
  'productData/getProducts',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await getProductsAPI();
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const uploadFile = createAsyncThunk(
  'productData/uploadFile',
  async (payload: FormData, { rejectWithValue }) => {
    try {
      const response = await uploadFileAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteProduct = createAsyncThunk(
  'agentData/deleteProduct',
  async (payload: ProductDataModel, { rejectWithValue }) => {
    try {
      const response = await deleteProductAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Slice
export const productDataSlice = createSlice({
  name: 'productData',
  initialState,
  reducers: {
    setLoading: (state: ProductState, action) => {
      state.loading = action?.payload;
    },
    setIsError: (state: ProductState, action) => {
      state.isError = action.payload;
    },
    setErrorMsg: (state: ProductState, action) => {
      state.errorMsg = '';
    },
    setIsSuccess: (state: ProductState, action) => {
      state.successMsg = action.payload;
    },
    setIsUpdate: (state: ProductState, action) => {
      state.isUpdate = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProducts.pending, (state: ProductState) => {
        state.loading = true;
        state.isError = false;
      })
      .addCase(getProducts.fulfilled, (state: ProductState, action) => {
        state.loading = false;
        if (action.payload) {
          const products = action?.payload;
          state.products = products;
        }
      })
      .addCase(getProducts.rejected, (state: ProductState, action: any) => {
        state.products = [];
        state.loading = false;
        state.isError = true;
        state.errorMsg = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(uploadFile.pending, (state: ProductState) => {
        state.loading = true;
      })
      .addCase(uploadFile.fulfilled, (state: ProductState, action) => {
        state.loading = false;
        state.isUpdate = true;
      })
      .addCase(uploadFile.rejected, (state: ProductState, action: any) => {
        state.loading = false;
        state.isError = true;
        state.errorMsg = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(deleteProduct.pending, (state: ProductState) => {
        state.loading = true;
      })
      .addCase(deleteProduct.fulfilled, (state: ProductState, action) => {
        state.loading = false;
        state.isUpdate = true;
      })
      .addCase(deleteProduct.rejected, (state: ProductState, action: any) => {
        state.loading = false;
        state.isError = true;
        state.errorMsg = action?.payload?.message
          ? action?.payload?.message
          : '';
      });
  }
});

export const {
  setIsError,
  setIsSuccess,
  setLoading,
  setErrorMsg,
  setIsUpdate
} = productDataSlice.actions;
export default productDataSlice.reducer;
