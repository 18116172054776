import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export interface TextEditorProps {
  textEditorHeight: string;
  textEditorControls: object;
  placeholder;
  required: false;
  value: string;
  onChangeText: (value) => void;
}

const TextEditor = ({
  textEditorHeight,
  textEditorControls,
  onChangeText,
  value,
  placeholder
}: TextEditorProps) => {
  return (
    <ReactQuill
      className="text-editor-container"
      value={value}
      placeholder={placeholder}
      style={{ height: textEditorHeight }}
      modules={textEditorControls}
      onChange={onChangeText}
    />
  );
};

export default TextEditor;
