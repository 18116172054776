import { Suspense, lazy } from 'react';
import { RouteObject } from 'react-router';
import BaseLayout from 'src/core';
// import { GuardedRoute } from './guarded-routes';
import * as MODULE from './../shared/constants/constants';
import { SuspenseLoader } from 'src/shared/components/index';
import * as ROUTES from '../shared/constants/routes';
import OrganizationLayout from 'src/core/layout/OrganizationLayout';
import { GuardedRoute } from './guarded-routes';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages
const Login = Loader(lazy(() => MODULE.LOGIN));
const ErrorPage = Loader(lazy(() => MODULE.ERROR_PAGE));
const StyleGuide = Loader(lazy(() => MODULE.STYLE_GUIDE));

// Dashboards
// const UserManagement = Loader(lazy(() => MODULE.USER_MANAGEMENT));
// const Profile = Loader(lazy(() => MODULE.PROFILE));
const Dashboard = Loader(lazy(() => MODULE.DASHBOARD));
const POLICY_LEADS = Loader(lazy(() => MODULE.POLICY_LEADS));
const ADD_ISSUANCE = Loader(lazy(() => MODULE.ADD_ISSUANCE));
const AGENT = Loader(lazy(() => MODULE.AGENT));
const PRODUCT = Loader(lazy(() => MODULE.PRODUCT));
const ADD_POLICY_DETAILS = Loader(lazy(() => MODULE.ADD_POLICY_DETAILS));

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: ROUTES.LOGIN,
        element: <Login />
      }
    ]
  },
  {
    path: '',
    element: <OrganizationLayout />,
    children: [
      {
        path: ROUTES.DASHBOARD,
        element: <Dashboard />
      },
      {
        path: ROUTES.AGENT,
        element: <AGENT />
      },
      {
        path: ROUTES.PRODUCT,
        element: <PRODUCT />
      },
      {
        path: ROUTES.ADD_ISSUANCE,
        element: <ADD_ISSUANCE />
      },
      {
        path: ROUTES.ADD_POLICY_DETAILS,
        element: <ADD_POLICY_DETAILS />
      }
    ]
  },
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: ROUTES.STYLE_GUIDE,
        element: <StyleGuide />
      }
    ]
  },
  {
    path: ROUTES.ERROR_PAGE,
    element: <ErrorPage />
  }
];

export default routes;
