import { Divider, useTheme } from '@mui/material';
import { useNavigate } from 'react-router';
import * as ROUTES from 'src/shared/constants/routes';
import { ROLES } from 'src/shared/constants/constants';
import { selectUserState } from 'src/auth/login/services/userSlice';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAuth } from 'src/providers/AuthguardContext';
import voloronIcon  from 'src/assets/images/Volaron_logo.svg';

export const TopHeader = () => {
  const theme = useTheme();
  const { userData, loginUserRole } = useSelector(selectUserState);

  const ref = useRef<HTMLButtonElement>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const { logout } = useAuth();
  const navigate = useNavigate();

  const userLogout = async () => {
    await logout();
    localStorage.clear();
    navigate(ROUTES.LOGIN);
  };

  return (
    <>
      <div className="header-top-bar" />
      <div className="top-header-main-container">
        <div className="icon-text-container">
          <img src={voloronIcon}  />
          {/* <div className="volaron-text">Volaron</div> */}
        </div>
        <div className="profile-container">
          <div>CTI Portal</div>
          <Divider orientation="vertical" flexItem />
          <div>Hello {userData?.name || 'User'}</div>
          <Divider orientation="vertical" flexItem />
          <div
            className="logout-button"
            onClick={userLogout}
            style={{ color: theme.colors.primary.main }}
          >
            LOGOUT
          </div>
        </div>
      </div>
    </>
  );
};
