import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'src/store/reducer';
import {
  getAgentAPI,
  addAgentAPI,
  deleteAgentAPI,
  updateAgentAPI,
  uploadFileAPI
} from '../api/AgentApi';
import {
  initalPaginationData,
  Pagination,
  PaginationResponseDto
} from 'src/core/model/pagination.dto';
import { AgentModel } from '../model';
import { MultiSelectOption } from 'src/modules/Dashboard/model';

export interface AgentState {
  isError: boolean;
  isSuccess: boolean;
  isFileUploaded: boolean;
  successMsg: string;
  errorMsg: string;
  loading: boolean;
  isUpdate: boolean;
  agents: PaginationResponseDto<AgentModel>;
  multiSelectAgentDetails: MultiSelectOption[];
}

const initialState: AgentState = {
  isError: false,
  isFileUploaded: false,
  isUpdate: false,
  isSuccess: true,
  successMsg: '',
  errorMsg: '',
  loading: false,
  agents: initalPaginationData,
  multiSelectAgentDetails: []
};

export const selectorAgentState = ({ agentData }: RootState) => ({
  isError: agentData?.isError,
  isUpdate: agentData?.isUpdate,
  isFileUploaded: agentData?.isFileUploaded,
  isSuccess: agentData?.isSuccess,
  successMsg: agentData?.successMsg,
  errorMsg: agentData?.errorMsg,
  loading: agentData?.loading,
  agents: agentData?.agents,
  multiSelectAgentDetails: agentData?.multiSelectAgentDetails
});

// services
export const getAgent = createAsyncThunk(
  'agentData/getAgent',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await getAgentAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateAgent = createAsyncThunk(
  'agentData/updateAgent',
  async (payload: AgentModel, { rejectWithValue }) => {
    try {
      const response = await updateAgentAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const uploadFile = createAsyncThunk(
  'productData/uploadFile',
  async (payload: FormData, { rejectWithValue }) => {
    try {
      const response = await uploadFileAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addAgent = createAsyncThunk(
  'agentData/addAgent',
  async (payload: AgentModel, { rejectWithValue }) => {
    try {
      const response = await addAgentAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteAgent = createAsyncThunk(
  'agentData/deleteAgent',
  async (payload: AgentModel, { rejectWithValue }) => {
    try {
      const response = await deleteAgentAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Slice
export const agentDataSlice = createSlice({
  name: 'agentData',
  initialState,
  reducers: {
    setLoading: (state: AgentState, action) => {
      state.loading = action?.payload;
    },
    setIsError: (state: AgentState, action) => {
      state.isError = action.payload;
      if (!action.payload) {
        state.errorMsg = null;
      }
    },
    setErrorMsg: (state: AgentState) => {
      state.errorMsg = '';
    },
    setIsSuccess: (state: AgentState, action) => {
      state.isSuccess = action.payload;
      if (!action.payload) {
        state.successMsg = null;
      }
    },
    setSuccessMsg: (state: AgentState, action) => {
      state.successMsg = action.payload;
    },
    setIsUpdate: (state: AgentState, action) => {
      state.isUpdate = action.payload;
    },
    setisFileUploaded: (state: AgentState, action) => {
      state.isFileUploaded = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAgent.pending, (state: AgentState) => {
        state.loading = true;
        state.isError = false;
      })
      .addCase(getAgent.fulfilled, (state: AgentState, action) => {
        state.loading = false;
        if (action.payload) {
          const agentList = action?.payload;
          state.agents = agentList;

          // Transform data for multiselect
          const multiSelectOptions: MultiSelectOption[] =
            action.payload?.records?.map((agent: AgentModel) => ({
              id: agent?.id,
              key: agent?.id,
              label: agent?.tjAgentId,
              title: agent?.tjAgentId,
              create: false
            }));
          state.multiSelectAgentDetails = multiSelectOptions;
        }
      })
      .addCase(getAgent.rejected, (state: AgentState, action: any) => {
        state.loading = false;
        state.isError = true;
        state.errorMsg = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(deleteAgent.pending, (state: AgentState) => {
        state.loading = true;
        state.isError = false;
      })
      .addCase(deleteAgent.fulfilled, (state: AgentState, action) => {
        state.loading = false;
        state.isUpdate = true;
        state.isSuccess = true;
        state.successMsg = `Agent deleted successfully!`;
      })
      .addCase(deleteAgent.rejected, (state: AgentState, action: any) => {
        state.loading = false;
        state.isError = true;
        state.errorMsg = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(addAgent.pending, (state: AgentState) => {
        state.loading = true;
      })
      .addCase(addAgent.fulfilled, (state: AgentState, action) => {
        state.loading = false;
        state.isUpdate = true;
        state.isSuccess = true;
        state.successMsg = `Agent added successfully!`;
      })
      .addCase(addAgent.rejected, (state: AgentState, action: any) => {
        state.loading = false;
        state.isError = true;
        state.errorMsg = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(uploadFile.pending, (state: AgentState) => {
        state.loading = true;
      })
      .addCase(uploadFile.fulfilled, (state: AgentState, action) => {
        state.loading = false;
        state.isUpdate = true;
        state.isFileUploaded = true;
      })
      .addCase(uploadFile.rejected, (state: AgentState, action: any) => {
        state.loading = false;
        state.isError = true;
        state.errorMsg = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(updateAgent.pending, (state: AgentState) => {
        state.loading = true;
      })
      .addCase(updateAgent.fulfilled, (state: AgentState, action) => {
        state.loading = false;
        state.isUpdate = true;
        state.isSuccess = true;
        state.successMsg = action?.payload?.message;
      })
      .addCase(updateAgent.rejected, (state: AgentState, action: any) => {
        state.loading = false;
        state.isError = true;
        state.errorMsg = action?.payload?.message
          ? action?.payload?.message
          : '';
      });
  }
});

export const {
  setIsError,
  setIsSuccess,
  setLoading,
  setErrorMsg,
  setIsUpdate,
  setSuccessMsg,
  setisFileUploaded
} = agentDataSlice.actions;
export default agentDataSlice.reducer;
