import { Button as MuiButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ButtonProps } from './services/buttonInterface';

const useStyles = makeStyles({
  iconOnly: {
    '& .MuiButton-startIcon': {
      marginRight: 0,
      marginLeft: 0
    }
  }
});

export const Button = ({
  btnText,
  icon,
  btnClass,
  width,
  height,
  resendButtonCandidateTable,
  ...props
}: ButtonProps) => {
  const classes = useStyles();
  return (
    <>
      <MuiButton
        startIcon={icon}
        component="span"
        sx={{
          padding: resendButtonCandidateTable
            ? '3px 15px'
            : btnText
            ? '7px 20px'
            : '7px',
          ...(width && { minWidth: width }),
          ...(height && { height: height })
        }}
        className={btnText ? btnClass : classes.iconOnly}
        {...props}
      >
        {btnText}
      </MuiButton>
    </>
  );
};
