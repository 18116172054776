import { BULK_ISSUANCE_API, PRODUCT_API } from 'src/shared/constants/constants';
import { apiDelete, apiGet, apiPost } from 'src/shared/utils/api-request';
import { ProductDataModel } from '../model';

export const uploadFileAPI = async (payload: FormData) => {
  const res = await apiPost(PRODUCT_API.FILE_UPLOAD, payload, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return res.data;
};

export const getProductsAPI = async () => {
  const res = await apiGet(
    `${PRODUCT_API.GET}`
  );
  return res.data;
};

export const deleteProductAPI = async (payload: ProductDataModel) => {
  const res = await apiDelete(`product/${payload.id}`);
  return res;
};
