import { quationDataDto } from 'src/core/model/quatationData.dto';
import { Pager } from './../../../core/model/pagination.dto';
import { PaginationResponseDto } from 'src/core/model/pagination.dto';
import { viewPolicyTableDataDto } from 'src/core/model/viewPolicyTableData.dto';
import { singleIssuanceDataDto } from 'src/core/model/singleIssusanceData.dto';
import { string } from 'prop-types';
import dayjs from 'dayjs';
import { IssuanceType } from 'src/shared/constants/constants';

export interface BulkIssuanceTableRow {
  id: string;
  bulkIssuanceId: string;
  referenceId?: string;
  bookingId?: string;
  fileUploadDate: string;
  passengers: string;
  totalQuotation: string;
  status: string;
  actions: string;
  createdAt?: string;
  agent?: {
    tjAgentId?: string;
    name?: string;
  };
  commonDetails?: {
    fileName?: string;
  };
  quotations?: {
    totalCost?: number;
    totalPassengers?: number;
    passengerData?: QuatationTableRow[];
  };
}

export interface QuatationTableRow {
  id: number;
  ageRange: string;
  costPerPassenger?: string;
  totalPassengers: number;
  totalCost: number;
}

export interface ViewPolicyTableRow {
  id: string;
  personalEmailID: string;
  issuanceId: string;
  bookingId?: string;
  tjPolicyId: string;
  paxId?: string;
  insurerId: string;
  agent: {
    agentName: string;
    tjAgentId: string;
  };
  customerName: string;
  departureDate: string;
  returnDate: string;
  noOfDays: string;
  destination?: string;
  plan: string;
  actions: string;
}

export interface SingleIssuanceTableRow {
  id: string;
  issuanceId: string;
  name: string;
  tjPolicyId: string;
  insurerPolicyId: string;
  sendToAgent: boolean;
  sendToPassenger: boolean;
  downloadLink: string;
}

export type BulkIssuanceTableData = BulkIssuanceTableRow;
export type QuatationTableData = QuatationTableRow;
export type ViewPolicyTableData = ViewPolicyTableRow;
export type SingleIssuanceTable = SingleIssuanceTableRow;

export type GeneratedPolicyTableRow = {
  id: string;
  passengerId: string;
  issuanceId: string;
  name: string;
  travelerId: string;
  tjAgentId: string;
  tJPolicyId?: string;
  policyId?: string;
  insurerPolicyId?: string;
  sendToAgent?: boolean;
  sendToPassenger?: boolean;
  downloadLink?: string;
};

export type GeneratedPolicyTableRowWithDetail = {
  details?: GeneratedPolicyTableRow;
  id?: string;
};

export interface CommomPayload {
  issuanceId: string;
}
export type DownloadAllZipPayload = CommomPayload;
export type SendPolicyToPassengersPayload = CommomPayload;

export type SendPolicyToOrganiserPayload = {
  issuanceId: string;
  organiserEmail: string;
};

export interface GeneratePolicyPayload {
  referenceId: string;
  issuanceType: IssuanceType;
}

export type ViewQuotationPayload = {
  referenceId: string;
};

export type SendPolicyPolicyPayload = {
  issuanceId: string;
  passengers: [
    {
      id: string;
      sendToAgent: boolean;
      sendToPax: boolean;
    }
  ];
};

export interface SingleIssuanceTableData {
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: any;
  createdBy?: any;
  updatedBy?: string;
  deletedBy?: any;
  id: string;
  bookingId: string;
  referenceId: string;
  policyPlan: any;
  agent: {
    id: string;
    name: string;
    email: string;
    tjAgentId: string;
    mobileNumber: string;
  };
  insurerName: string;
  issuanceType: string;
  status: string;
  commonDetails: CommonDetails;
  quotations: any;
}

export interface CommonDetails {
  geoLocation: string;
  tripEndDate: string;
  tripStartDate: string;
  numberOfPassengers: number;
}

export type DownloadPolicyPayload = {
  toDate: dayjs.Dayjs;
  fromDate: dayjs.Dayjs;
  search: string;
};

export type SendPolicyPayload = {
  issuanceId?: string;
  organiserEmail?: string;
};

export type saveQuotationPayload = {
  totalCost: number;
  referenceId: string;
  selectedQuotations: [];
};

export interface GeneratePolicyResponseData {
  referenceId: string;
  generatedPolicies: GeneratedPolicy[];
}

export interface GeneratedPolicy {
  details: Details;
}

export interface Details {
  travelerId: string;
  passengerId: string;
  passengerName: string;
  tjAgentId: string;
  policyId: string;
}

export type TabPassingDataType = {
  tabId?: number;
  data?: { viewPolicySearchParam?: string };
};

export type GroupErrorObject = {
  type?: string;
  errorCount?: number;
  errorMessage?: string;
  rows?: string[];
};

export type GroupValidationError = {
  type?: string;
  errorCount?: number;
  errorMessage?: string;
  rows?: string[];
};

export type DocumentViewerModal = {
  uri: string;
  fileType: string;
};

export type MultiSelectOption = {
  id?: string;
  key?: string;
  title?: string;
  label?: string;
  inputValue?: string;
  create?: any;
};

export const GeneratePolicyResponseInitialData = {
  data: {
    referenceId: '',
    generatedPolicies: [
      {
        details: {
          travelerId: '',
          passengerId: '',
          passengerName: '',
          tjAgentId: '',
          policyId: ''
        }
      }
    ]
  }
};
export const singleIssuanceTabel: singleIssuanceDataDto<SingleIssuanceTable> = {
  issuanceId: 'S001',
  passengers: [
    {
      id: '01',
      name: 'Passenger 1',
      tjPolicyId: 'TJ190283X001',
      insurerPolicyId: 'A289134',
      sendToAgent: true,
      sendToPassenger: true,
      downloadLink: 'link-to-download-policy-1',
      issuanceId: ''
    }
  ],
  downloadLink: '',
  sendToPassenger: true,
  name: '',
  tjPolicyId: '',
  insurerPolicyId: '',
  sendToAgent: false
};
