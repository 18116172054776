export const LOGIN = '/';
export const STYLE_GUIDE = '/style-guide';
export const SET_PASSWORD = '/set-password';
export const FORGET_PASSWORD = '/forget-password';
export const ADMIN_FORGET_PASSWORD = '/admin/forget-password';
export const CHECK_YOUR_EMAIL = '/check-your-email';
export const ERROR_PAGE = '/not-found';

export const ADMIN_LOGIN = '/admin';
export const CANDIDATE_LOGIN = '/candidate';
export const TENANT_LOGIN = '/tenant';

export const USER_MANAGEMENT = '/user-management';
export const WELCOME = '/welcome';
export const PROFILE = '/profile';

export const DASHBOARD = '/dashboard';
export const ADD_ISSUANCE = '/add-issuance';
export const ADD_POLICY_DETAILS = '/add-policy-details';
export const LEADS = '/leads';
export const AGENT = '/agent';
export const PRODUCT = '/product';
