import { Tooltip } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
export const OverflowToolTip = (props: { children: React.ReactNode }) => {
  // Create Ref
  const textElementRef = useRef<HTMLDivElement>(null);
  const compareSize = () => {
    if (!textElementRef.current) return;
    if (
      !textElementRef.current.scrollWidth ||
      !textElementRef.current.clientWidth
    )
      return;
    const compare =
      textElementRef?.current?.scrollWidth >
      textElementRef?.current?.clientWidth;
    setHover(compare);
  };
  // compare once and add resize listener on "componentDidMount"
  useEffect(() => {
    compareSize();
    window.addEventListener('resize', compareSize);
    return () => {
      window.removeEventListener('resize', compareSize);
    };
  }, []);
  // Define state and function to update the value
  const [hoverStatus, setHover] = useState(false);
  const innerText = useMemo(() => {
    return textElementRef.current?.innerText;
  }, [textElementRef.current]);
  return (
    <Tooltip title={innerText ?? ''} disableHoverListener={!hoverStatus}>
      <div ref={textElementRef} className="text-ellipsis">
        {props.children}
      </div>
    </Tooltip>
  );
};
