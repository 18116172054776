import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/store/reducer';
import {
  getBulkIssuanceTravelerAPI,
  uploadFileAPI
} from '../api/BulkIssuanceApi';
import {
  initalPaginationData,
  Pagination,
  PaginationResponseDto
} from 'src/core/model/pagination.dto';
import {
  BulkIssuanceTableRow,
  GroupValidationError,
  QuatationTableData,
  QuatationTableRow,
  TabPassingDataType
} from 'src/modules/Dashboard/model';
import { getErrorMessage, groupValidationErrors } from 'src/shared/utils/utils';
import { API_STATUS_CODE } from 'src/shared/constants/constants';

export interface BulkIssuanceState {
  isError: boolean;
  isSuccess: boolean;
  successMsg: string;
  errorMsg: string;
  loading: boolean;
  tableLoading: boolean;
  bulkTravelerData: PaginationResponseDto<QuatationTableData>;
  fileValidationErrors: {
    status: boolean;
    noOfErrors: number;
    errors: string[];
  };
  groupFileValidationErrors: GroupValidationError[];
  viewQuoteModalData: QuatationTableRow[];
  fileUploadedData?: BulkIssuanceTableRow;
  selectedBulkFileDataId?: string;
  noOfPassengers?: number;
  tabId?: number;
  tabPassingData?: TabPassingDataType;
}

const initialState: BulkIssuanceState = {
  isError: false,
  isSuccess: true,
  successMsg: '',
  errorMsg: '',
  loading: false,
  tableLoading: false,
  viewQuoteModalData: [],

  bulkTravelerData: initalPaginationData,
  fileValidationErrors: { status: false, noOfErrors: 0, errors: [] },
  groupFileValidationErrors: [],
  fileUploadedData: {
    id: '',
    bulkIssuanceId: '',
    referenceId: '',
    bookingId: '',
    fileUploadDate: '',
    passengers: '',
    totalQuotation: '',
    status: '',
    actions: '',
    createdAt: '',
    commonDetails: {
      fileName: ''
    },
    quotations: {
      totalCost: 0,
      totalPassengers: 0,
      passengerData: []
    }
  },
  selectedBulkFileDataId: '',
  noOfPassengers: 0,
  tabId: 0,
  tabPassingData: { tabId: 0, data: { viewPolicySearchParam: '' } }
};

export const selectorBulkIssuanceState = ({ bulkIssuanceData }: RootState) => ({
  isError: bulkIssuanceData?.isError,
  isSuccess: bulkIssuanceData?.isSuccess,
  successMsg: bulkIssuanceData?.successMsg,
  errorMsg: bulkIssuanceData?.errorMsg,
  loading: bulkIssuanceData?.loading,
  tableLoading: bulkIssuanceData?.tableLoading,
  viewQuoteModalData: bulkIssuanceData?.viewQuoteModalData,
  bulkTravelerData: bulkIssuanceData?.bulkTravelerData,
  fileValidationErrors: bulkIssuanceData?.fileValidationErrors,
  groupFileValidationErrors: bulkIssuanceData?.groupFileValidationErrors,
  fileUploadedData: bulkIssuanceData?.fileUploadedData,
  selectedBulkFileDataId: bulkIssuanceData?.selectedBulkFileDataId,
  noOfPassengers: bulkIssuanceData?.noOfPassengers,
  tabId: bulkIssuanceData?.tabId,
  tabPassingData: bulkIssuanceData?.tabPassingData
});

// services
export const uploadFile = createAsyncThunk(
  'bulkIssuanceData/uploadFile',
  async (payload: FormData, { rejectWithValue }) => {
    try {
      const response = await uploadFileAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getBulkIssuanceTraveler = createAsyncThunk(
  'bulkIssuanceData/getBulkIssuanceTraveler',
  async (payload: Pagination, { rejectWithValue }) => {
    try {
      const response = await getBulkIssuanceTravelerAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Slice
export const bulkIssuanceSlice = createSlice({
  name: 'bulkIssuanceData',
  initialState,
  reducers: {
    setLoading: (state: BulkIssuanceState, action) => {
      state.loading = action?.payload;
    },
    setIsError: (state: BulkIssuanceState, action) => {
      state.isError = action?.payload;
    },
    setErrorMsg: (state: BulkIssuanceState, action) => {
      state.errorMsg = action?.payload || '';
    },
    setSuccessMsg: (state: BulkIssuanceState) => {
      state.successMsg = '';
    },
    setIsSuccess: (state: BulkIssuanceState, action) => {
      state.isSuccess = action?.payload;
    },
    setTabId: (state: BulkIssuanceState, action) => {
      state.tabId = action?.payload;
    },
    resetBulkTableData: (state: BulkIssuanceState) => {
      const resetData = initalPaginationData;
      state.bulkTravelerData = resetData;
    },
    resetGroupFileValidationErrors: (state: BulkIssuanceState) => {
      const resetData = [];
      state.groupFileValidationErrors = resetData;
    },
    setTabPassingData: (
      state: BulkIssuanceState,
      action: PayloadAction<TabPassingDataType>
    ) => {
      state.tabId = action.payload.tabId;
      state.tabPassingData = action.payload;
    },
    resetFileValidationErrors: (state: BulkIssuanceState) => {
      const fileValidationErrors = { noOfErrors: 0, errors: [], status: false };
      state.fileValidationErrors = fileValidationErrors;
    },
    setViewQuoteModalData: (state: BulkIssuanceState, action) => {
      const item: any = state.bulkTravelerData?.records?.find(
        (entry) => entry.id === action.payload
      );

      if (!item) return;

      const passengerData = item?.quotations?.passengerData || [];

      if (Array.isArray(passengerData)) {
        // Add an ID to each passenger
        passengerData.forEach((item, index) => {
          item.id = `${index + 1}`;
        });

        // Assign passenger data to state
        state.viewQuoteModalData = passengerData;
      } else {
        // Handle cases where passengerData is missing or not an array
        state.viewQuoteModalData = [];
      }

      state.selectedBulkFileDataId = item?.referenceId;
      state.noOfPassengers = item?.quotations?.totalPassengers;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadFile.pending, (state: BulkIssuanceState) => {
        state.loading = true;
        state.isError = false;
      })
      .addCase(uploadFile.fulfilled, (state: BulkIssuanceState, action) => {
        state.loading = false;
        if (action.payload) {
          state.successMsg = action?.payload?.message;

          if (action.payload?.noOfErrors > 0) {
            const fileValidationErrors = {
              status: true,
              errors: action?.payload?.errors || [],
              noOfErrors: action?.payload?.noOfErrors
                ? Number(action?.payload?.noOfErrors)
                : 0
            };
            state.fileValidationErrors = fileValidationErrors;
          } else {
            const passengerData =
              action?.payload?.data?.quotations?.passengerData;
            if (Array.isArray(passengerData)) {
              passengerData.forEach((item, index) => {
                item.id = `${index + 1}`;
              });

              state.viewQuoteModalData = passengerData;
            } else {
              state.viewQuoteModalData = [];
            }

            state.selectedBulkFileDataId =
              action?.payload?.data?.referenceId || null;
            state.noOfPassengers =
              action?.payload?.data?.quotations?.totalPassengers || 0;
          }
        }
      })
      .addCase(uploadFile.rejected, (state: BulkIssuanceState, action: any) => {
        state.loading = false;
        state.isError = true;

        state.errorMsg =
          action.payload?.data?.message ||
          (action.payload?.statusCode ===
          Number(API_STATUS_CODE.INTERNAL_SERVER_ERRROR)
            ? getErrorMessage({
                statusCode: action.payload?.statusCode
              })
            : action.payload?.message);
      })
      .addCase(getBulkIssuanceTraveler.pending, (state: BulkIssuanceState) => {
        state.tableLoading = true;
      })
      .addCase(
        getBulkIssuanceTraveler.fulfilled,
        (state: BulkIssuanceState, action) => {
          state.tableLoading = false;
          if (action.payload) {
            state.bulkTravelerData = action?.payload;
          }
        }
      )
      .addCase(
        getBulkIssuanceTraveler.rejected,
        (state: BulkIssuanceState, action: any) => {
          state.tableLoading = false;
          state.isError = true;
          state.errorMsg = action?.payload?.message
            ? action?.payload?.message
            : '';
        }
      );
  }
});

export const {
  setTabId,
  setIsError,
  setIsSuccess,
  setLoading,
  setErrorMsg,
  setSuccessMsg,
  resetBulkTableData,
  setTabPassingData,
  setViewQuoteModalData,
  resetFileValidationErrors,
  resetGroupFileValidationErrors
} = bulkIssuanceSlice.actions;
export default bulkIssuanceSlice.reducer;
