import { AGENT_API, IssuanceType } from 'src/shared/constants/constants';
import {
  apiDelete,
  apiGet,
  apiPost,
  apiPut
} from 'src/shared/utils/api-request';
import { AgentModel } from '../model';

export const updateAgentAPI = async (payload: AgentModel) => {
  const res = await apiPut(`agent/${payload.id}`, payload);
  return res.data;
};

export const getAgentAPI = async (payload: any) => {
  const res = await apiGet(
    `agent?page=${payload.page}&limit=${payload.limit}&search=${
      payload.search || ''
    }&active=${payload.active || ''}&status=${
      payload.status || ''
    }&issuanceType=${IssuanceType.BULK}`
  );
  return res.data;
};

export const uploadFileAPI = async (payload: FormData) => {
  const res = await apiPost(AGENT_API.FILE_UPLOAD, payload, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return res.data;
};

export const deleteAgentAPI = async (payload: AgentModel) => {
  const res = await apiDelete(`agent/${payload.id}`);
  return res;
};

export const addAgentAPI = async (payload: AgentModel) => {
  const res = await apiPost(AGENT_API.GET, payload);
  return res;
};
