import { Pagination } from 'src/core/model/pagination.dto';
import {
  DownloadAllZipPayload,
  GeneratePolicyPayload,
  SendPolicyToOrganiserPayload,
  SendPolicyToPassengersPayload
} from 'src/modules/Dashboard/model';
import {
  BULK_ISSUANCE_API,
  IssuanceType
} from 'src/shared/constants/constants';
import {
  ApiResponse,
  Config,
  apiDelete,
  apiGet,
  apiPatch,
  apiPost,
  apiPut
} from 'src/shared/utils/api-request';

export const uploadFileAPI = async (payload: FormData) => {
  const res = await apiPost(BULK_ISSUANCE_API.FILE_UPLOAD, payload, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return res.data;
};

export const getBulkIssuanceTravelerAPI = async (payload: Pagination) => {
  const res = await apiGet(
    `${BULK_ISSUANCE_API.GET_BULK_ISSUANCE}?page=${payload.page}&limit=${
      payload.limit
    }${(payload.search && `&search=${payload.search}`) || ''}${
      (payload.active && `&active=${payload.active}`) || ''
    }${(payload.status && `&status=${payload.status || ''}`) || ''}${
      (payload.IssuanceType && `&issuanceType=${IssuanceType.BULK}`) || ''
    }`
  );
  return res.data;
};

export const downloadAllPolicyAPI = async (payload: DownloadAllZipPayload) => {
  const res = await apiGet(
    `${BULK_ISSUANCE_API.DOWNLOAD_ALL_POLICY}/${payload.issuanceId}`,
    {
      responseType: 'blob' // Ensure the response is treated as a blob
    }
  );
  return res;
};

export const generatePolicyAPI = async (payload: GeneratePolicyPayload) => {
  const res = await apiPost(BULK_ISSUANCE_API.GENERATE_POLICY, payload);
  return res;
};

export const policySendToAllIndividualPassengersAPI = async (
  payload: SendPolicyToPassengersPayload
) => {
  const res = await apiPost(
    BULK_ISSUANCE_API.SEND_POLICY_TO_INDIVIDUALS,
    payload
  );
  return res;
};

export const policySendToOrginizerAPI = async (
  payload: SendPolicyToOrganiserPayload
) => {
  const res = await apiPost(
    BULK_ISSUANCE_API.POLICY_SEND_TO_ORGANISER,
    payload
  );
  return res;
};
