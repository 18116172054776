import { DecodedToken } from 'src/auth/login/models/login';
import {
  ACCESS_TOKEN,
  CHAT_BOT_API_TOKEN,
  DECODED_TOKEN,
  REFRESH_TOKEN
} from '../constants/constants';

const setItem = (key: string, value: string) => {
  localStorage.setItem(key, value);
};
const getItem = (key: string) => localStorage.getItem(key) || null;

export const getAccessToken = () => getItem(ACCESS_TOKEN) || '';

export const setAccessToken = (token: string) => {
  setItem(ACCESS_TOKEN, token);
  setItem(CHAT_BOT_API_TOKEN, token);
};

export const getDecodedToken = () => getItem(DECODED_TOKEN) || '';

export const setDecodedToken = (token: DecodedToken) => {
  setItem(DECODED_TOKEN, JSON.stringify(token));
};

export const setRefreshToken = (token: string) => {
  setItem(REFRESH_TOKEN, token);
};

export const getRefreshToken = () => getItem(REFRESH_TOKEN) || '';

export const clearStorage = () => {
  localStorage.clear();
  sessionStorage.clear();
  setAccessToken('');
  setDecodedToken(null);
  setRefreshToken('');
};
