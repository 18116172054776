import { Dayjs } from 'dayjs';

export type CommonDetails = {
  geoLocation: string;
  tripEndDate: string;
  tripStartDate: string;
  numberOfPassengers: number;
};

export type AgentDetails = {
  id: string;
  tjAgentId: string;
  name: string;
  email: string;
  mobileNumber: string;
};

export interface SingleIssuanceTableRow {
  id: string;
  singleIssuanceId: string;
  bookingId: string;
  agent: AgentDetails;
  passengers: string;
  totalQuotation: string;
  issuanceCreated: string;
  status: string;
  actions: string;
  referenceId?: string;
  commonDetails?: CommonDetails;
  quotations?: {
    totalCost?: string;
  };
}

export interface Agent {
  tjId: string;
  email: string;
  mobileNumber: string;
  name: string;
}

export interface PassengerDetails {
  key: number;
  id: string;
  name: string;
  geoLocation?: string;
  departureDate: Dayjs;
  returnDate: Dayjs;
  birthDate: Dayjs;
  age: number;
}

export interface AccordianFormDetails {
  referenceId: string;
  geoLocation: string;
  numberOfPassengers: number;
  departureDate: Dayjs;
  returnDate: Dayjs;
  insurerName: string;
  noOfDays: number;
  agent: Agent;
  passengerDetails: PassengerDetails[];
}

export type InitialFormData = {
  geography_location: string;
  no_of_passengers: string;
  departure_date: string;
  return_date: string;
  agent_id: string;
  agent_email: string;
  agent_mb_number: string;
  insurer_name: string;
  insurer_code: string;
  agent_name: string;
};

export type AddPolicyDetailsForm = {
  agent_id: string;
  agent_name: string;
  agent_email: string;
  agent_mb_number: string;
  TJ_booking_id: string;
  geography_location: string;
  no_of_passengers: string;
  departure_date: string;
  return_date: string;
  no_of_days: string;
  net_premium: string;
};

export const InitialAccordianFormDetails: AccordianFormDetails = {
  geoLocation: '',
  referenceId: '',
  numberOfPassengers: null,
  departureDate: null,
  returnDate: null,
  insurerName: '',
  noOfDays: 0,
  agent: {
    tjId: '',
    email: '',
    mobileNumber: '',
    name: ''
  },
  passengerDetails: []
};

export type SingleIssuanceTableData = SingleIssuanceTableRow;
