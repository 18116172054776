import { Pagination } from 'src/core/model/pagination.dto';
import {
  DocumentViewerModal,
  DownloadPolicyPayload,
  SendPolicyPayload
} from 'src/modules/Dashboard/model';
import {
  DropdownStatusOptions,
  IssuanceType,
  PolicyLastActive,
  PolicyStatus
} from 'src/shared/constants/constants';
import {
  ApiResponse,
  Config,
  apiDelete,
  apiGet,
  apiPatch,
  apiPost,
  apiPut
} from 'src/shared/utils/api-request';

export const getViewIssuanceTravelerTableAPI = async (payload: Pagination) => {
  const res = await apiGet(
    `issuance/policy?page=${payload.page}&limit=${payload.limit}&search=${
      payload.search || ''
    }&startDate=${payload.startDate || ''}&endDate=${payload.endDate || ''}`
  );
  return res.data;
};

export const getPolicyDownloadAPI = async (payload: Pagination) => {
  const res = await apiGet(
    `issuance/policy/download/${payload.downloadType}?&search=${
      payload.search || ''
    }&startDate=${payload.startDate || ''}&endDate=${payload.endDate || ''}`,
    {
      responseType: 'blob' // Ensure the response is treated as a blob
    }
  );
  return res;
};

export const sendPolicyToPassengersAPI = async (payload: SendPolicyPayload) => {
  const res = await apiPost(`issuance/send-policy/passenger`, payload);
  return res;
};

export const policyPDFPreviewAPI = async (payload: {
  paxId: string;
}): Promise<any> => {
  const res = await apiGet(`issuance/single/download/${payload?.paxId}`, {
    responseType: 'blob'
  });
  return res;
};
