import React from 'react';
import { Box, TextField as MuiTextField, Typography } from '@mui/material';
import { TextFieldProps } from './services/textFieldInterface';

export const TextField = ({ ...props }: TextFieldProps) => {
  const {
    margin = 'normal',
    required = false,
    id = 'validation-outlined-input',
    label,
    name,
    type = 'text',
    value = '',
    variant = 'outlined',
    defaultValue = null,
    error = false,
    helperText = '',
    disabled = false,
    InputProps,
    inputProps,
    onBlur,
    placeholder = 'Enter here',
    onChange,
    onKeyDown,
    multiline = false
  } = props;

  function onKeyDownEvent(event) {
    if (onKeyDown) {
      onKeyDown(event);
    } else if (
      event.key === 'Enter' &&
      event.target instanceof HTMLInputElement
    ) {
      console.log('searching for:' + event.target.value);
    }
  }

  function handleKeyDown(event) {
    if (onKeyDown) {
      onKeyDown(event);
    } else if (event.keyCode === 13) {
      console.log('Enter key was pressed');
    }
  }

  return (
    <Box className={`${multiline ? 'multilineInput' : ''} textFields`}>
      <Typography variant="body2" sx={{ mb: '6px' }} align="left">
        <Box className="d-flex">
          <Box>
            {label}
            {required && <span style={{ color: '#E73A35' }}>*</span>}
          </Box>
        </Box>
      </Typography>
      <MuiTextField
        className="w-100"
        name={name}
        value={value}
        required={required}
        placeholder={placeholder}
        onChange={(e) => onChange(e)}
        onKeyDown={handleKeyDown}
        onBlur={onBlur}
        variant={variant}
        disabled={disabled}
        id={id}
        multiline={multiline}
        type={type}
        error={error}
        helperText={helperText}
        inputProps={{ ...inputProps }}
        InputProps={{
          ...InputProps
        }}
        onFocus={(event) => {
          //adding this line to remove browser autofill
          event.target.setAttribute('autocomplete', 'new-password');
        }}
      />
    </Box>
  );
};
export default TextField;
