import { Box, Container, Grid, useTheme } from '@mui/material';
import { CustomSidebar, TopHeader } from '../../shared/components';
import { FC, ReactNode } from 'react';
import { Outlet } from 'react-router-dom';

interface OrganizationLayoutProps {
  children?: ReactNode;
  routeName?: String;
}

const OrganizationLayout: FC<OrganizationLayoutProps> = () => {
  const theme = useTheme();

  //HTML
  return (
    <Box
      className={
        theme.colors.error.main === 'dark'
          ? 'layoutbox MuiPageTitlewrapperDark '
          : 'layoutbox MuiPageTitlewrapperLight '
      }
    >
      <TopHeader />
      <Box className="layout" sx={{ px: { xs: 0, lg: 3 }, py: 5 }}>
        <Box sx={{ pt: 1 }} display="block">
          <Container maxWidth={false} sx={{ px: { xs: 4, lg: 1 } }}>
            <Grid container spacing={6}>
              <Grid item xs={12} sm={12} md={1.6} lg={0.9} xl={1}>
                <CustomSidebar />
              </Grid>
              <Grid
                sx={{ px: 0 }}
                item
                xs={12}
                sm={12}
                md={10.4}
                lg={11.1}
                xl={11}
                className="organizationViewContainer"
              >
                <Outlet />
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </Box>
  );
};

export default OrganizationLayout;
