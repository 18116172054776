import { Config, apiDelete, apiGet, apiPatch, apiPost, apiPut } from 'src/shared/utils/api-request';

export const getAdminDataAPI = async () => {
  const config = {} as Config;
  config.custom = {
    setLoading: () => {}
  };
  const res = await apiGet('/admin/user');
  return res.data;
};

export const deleteAdminAPI = async (userEmail: any) => {
  const config = {} as Config;
  config.custom = {
    setLoading: () => {}
  };
  const res = await apiDelete(`/admin/user/${userEmail}`);
  return res.data;
};

export const addAdminUserAPI = async (payload: any) => {
  const res = await apiPost('/admin/user', payload);
  return res.data;
};

export const updateAdminUserAPI = async (userData: any) => {
  const res = await apiPut(`/admin/user/${userData.email}/organization`, {tenants: userData.tenants});
  return res.data;
};

export const updateAdminUserStatusAPI = async (userData: any) => {
  const res = await apiPatch(`/admin/user/${userData.email}/enable`, {value: userData.value, });
  return res.data;
};

