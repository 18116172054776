import {
  Box,
  Grid,
  Pagination,
  Skeleton,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  DataGrid,
  GridCallbackDetails,
  GridColumnVisibilityModel,
  GridEventListener,
  GridInputRowSelectionModel,
  GridRowSelectionModel,
  GridSortModel
} from '@mui/x-data-grid';
// import { OverflowToolTip, Typography } from '@shared/components';

import { TABLE_PAGESIZE } from 'src/shared/constants/constants';
import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import Typography from '../typography/Typography';
import { OverflowToolTip } from '../overflow-tool-tip/OverflowToolTip';
import { Pager } from 'src/core/model/pagination.dto';
import MyCustomOverlay from 'src/assets/images/indexnine-logo.svg';

const dummyRowsForSkeletonLoader = [
  { id: 1, lastName: 'Snow', firstName: 'Jon', age: 14 },
  { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 31 },
  { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 31 },
  { id: 4, lastName: 'Stark', firstName: 'Arya', age: 11 },
  { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
  { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
  { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
  { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
  { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 }
];

interface TableProps {
  rows?: any[];
  columns: any[];
  checkboxSelection?: boolean;
  loading?: boolean;
  className?: string;
  isShowCustomPagination?: boolean;
  // totalPages?: number;
  // currentPage?: number;
  totalItems?: number;
  paginationData?: any;
  columnVisibility?: GridColumnVisibilityModel | undefined;
  sortModel?: GridSortModel;
  hideTableFooter?: boolean;
  keepNonExistentRowsSelected?: boolean;
  rowSelectionModel?: GridInputRowSelectionModel | undefined;
  handleNextPaginationData?: (rowsPerPage: number, currentPage: number) => void;
  handleSortChange?: (model: GridSortModel) => void;
  onRowSelectionModelChange?:
    | ((
        rowSelectionModel: GridRowSelectionModel,
        details: GridCallbackDetails<any>
      ) => void)
    | undefined;
  handleRowClickEvent?: GridEventListener<'rowClick'>;
}

const useStyles = makeStyles({
  datatable: {
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
      outline: 'none !important'
    }
  }
});
export const Table = (props: TableProps) => {
  const {
    rows,
    columns,
    checkboxSelection = false,
    loading,
    className,
    // totalPages = 1,
    // currentPage,
    totalItems,
    columnVisibility,
    sortModel,
    paginationData,
    hideTableFooter = false,
    rowSelectionModel,
    keepNonExistentRowsSelected,
    handleNextPaginationData: getNextPaginationData,
    handleSortChange,
    onRowSelectionModelChange,
    handleRowClickEvent
  } = props;

  //Constants
  const classes = useStyles();
  const noOfRowPerPage = TABLE_PAGESIZE;
  const theme = useTheme();
  const isMD = useMediaQuery(theme.breakpoints.down('md'));

  //State Variables
  const [records, setRecords] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [open, setOpen] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(TABLE_PAGESIZE[0]);

  useEffect(() => {
    if (rows) {
      setRecords(rows);
      // setIsShowCustomPagination(false);
      // setShowDataTableFooters(true);
    }
  }, [rows]);

  useEffect(() => {
    if (paginationData?.records) {
      setRecords(paginationData?.records);
      const pager: Pager = paginationData?.pager;
      setTotalPages(pager?.totalPages);
      // setIsShowCustomPagination(true);
      // setShowDataTableFooters(false);
    }
  }, [paginationData]);

  //Method
  const handleClose = () => {
    setOpen(false);
  };

  const handleIconClick = () => {
    setOpen(true);
  };

  // function pageSizeChangeEvent(size: number) {
  //   setRowsPerPage(size);
  //   if (handleNextPaginationData) handleNextPaginationData(size, 1);
  // }

  function pageSizeChangeEvent(size) {
    setRowsPerPage(size);
    setCurrentPage(1);
    getNextPaginationData(size, 1);
  }

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
    getNextPaginationData(rowsPerPage, value);
  };

  // const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
  //   if (handleNextPaginationData) handleNextPaginationData(rowsPerPage, value);
  // };

  const handleSortModelChange = (
    model: GridSortModel,
    _details: GridCallbackDetails<any>
  ) => {
    if (handleSortChange) handleSortChange(model);
  };

  const renderPagination = () => {
    const totalResults = records?.length; // Assuming rows is the complete dataset

    const currentEndIndex = Math.min(currentPage * rowsPerPage, totalResults);

    const MyCustomRowsOverlay = () => {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <img src={MyCustomOverlay} alt="no-item" />
        </Box>
      );
    };

    return (
      <Grid
        container
        spacing={2}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Grid item xs={12} lg={6}>
          <Box
            sx={{ ml: { xs: 0, lg: 5 } }}
            className={clsx({
              ['flex-basic-center']: isMD,
              ['flex-basic-start']: !isMD,
              ['tablePagination']: true
            })}
          >
            <Typography className="text-small font-weight-300">
              {`Showing `}{' '}
              <span className="font-weight-bold">{currentEndIndex}</span>{' '}
              {` items out of `}{' '}
              <span className="font-weight-bold">{totalItems}</span>{' '}
              {`results found`}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Box
            sx={{ mb: { xs: 3, lg: 0 }, mr: { xs: 0, lg: 3 } }}
            className={clsx({
              ['flex-basic-center']: isMD,
              ['flex-basic-end']: !isMD
            })}
          >
            <Pagination
              className="pagination-class"
              count={totalPages}
              shape="rounded"
              boundaryCount={1}
              siblingCount={0}
              onChange={handleChange}
              page={currentPage}
            />
          </Box>
        </Grid>
      </Grid>
    );
  };

  const renderCell = () => (
    <Skeleton variant="rectangular" width={100} height={15} />
  );

  // Map columns to use custom cell renderer
  const renderColumns = useMemo(() => {
    if (loading) {
      return columns.map((col) => ({
        ...col,
        renderCell
      }));
    }

    return columns.map((col) => ({
      ...col,
      renderCell: (params: any) => {
        if (col.renderCell) {
          return <OverflowToolTip>{col.renderCell(params)}</OverflowToolTip>;
        }
        return <OverflowToolTip>{params.value}</OverflowToolTip>;
      }
    }));
  }, [columns, loading]);

  //HTML
  return (
    <Box className="tablestyle">
      <DataGrid
        rows={records}
        columns={renderColumns}
        columnVisibilityModel={columnVisibility}
        checkboxSelection={checkboxSelection}
        initialState={{ sorting: { sortModel } }}
        disableColumnFilter={false}
        pageSizeOptions={noOfRowPerPage}
        disableColumnMenu
        className={`table ${className ? className : ''}`}
        autoHeight={true}
        disableRowSelectionOnClick={true}
        disableDensitySelector={true}
        classes={{ root: classes.datatable }}
        localeText={{ noRowsLabel: 'No Results Found!' }}
        hideFooter={hideTableFooter}
        slots={{ footer: renderPagination }}
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        onRowSelectionModelChange={onRowSelectionModelChange}
        rowSelectionModel={rowSelectionModel}
        keepNonExistentRowsSelected={keepNonExistentRowsSelected}
        onRowClick={handleRowClickEvent}
      />
    </Box>
  );
};

export default Table;
