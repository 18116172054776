import { useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
// import { ROUTES } from '@shared/constants';
// import { useQuery } from '@shared/hooks';
// import { useAuth } from 'providers/AuthguardContext';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import DescriptionIcon from '@mui/icons-material/Description';
import * as ROUTES from '../../../shared/constants/routes';

export interface SideBarItem {
  id: number;
  path: string;
  name: string;
  icon: any;
}

export const CustomSidebar = () => {
  const theme = useTheme();
  const isMD = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  //   const { accessTokenPayload } = useAuth();
  //   const params = useQuery();
  //   const orgId = params.get('orgId') || accessTokenPayload?.organizationId || '';
  const sidebarItems: SideBarItem[] = [
    {
      id: 1,
      path: ROUTES.DASHBOARD,
      name: '',
      icon: <HomeRoundedIcon />
    },
    {
      id: 2,
      path: ROUTES.AGENT,
      name: '',
      icon: <GroupAddIcon />
    },
    {
      id: 3,
      path: ROUTES.PRODUCT,
      name: '',
      icon: <DescriptionIcon />
    }
  ];

  //State variables
  const [selectedMenu, setSelectedMenu] = useState<string | undefined>(
    ROUTES.DASHBOARD
  );

  //useEffects
  useEffect(() => {
    const menu = sidebarItems.find((m) => m.path === window.location.pathname);
    if (menu) {
      setSelectedMenu(window.location.pathname);
    }
  }, [window.location.pathname]);

  //methods
  const handleNavItemClick = (data: SideBarItem) => {
    setSelectedMenu(data?.path);
    navigate(`${data?.path}`);
  };

  const renderedSideBarItem = (sidebarItems: SideBarItem[]) => {
    return (
      <Box
        sx={{ pt: isMD ? 0 : 10, height: '100%' }}
        className={` ${isMD ? '' : 'h-100'} sideBar`}
      >
        <ul className="sidebar-menu-item">
          {sidebarItems?.map((item: SideBarItem) => (
            <li
              key={item.id}
              onClick={() => handleNavItemClick(item)}
              className={
                item?.path === selectedMenu &&
                selectedMenu?.includes(item?.path)
                  ? 'sidebar-menu active'
                  : 'sidebar-menu'
              }
            >
              <Box className="flex-basic-center iconWithItem">
                <Box className="flex-basic-start">
                  <div>{item.icon}</div>
                </Box>
                {/* <Box className="itemName">{item.name}</Box> */}
              </Box>
            </li>
          ))}
        </ul>
      </Box>
    );
  };

  return (
    <Box className="h-100 p-relative">
      <Box className="h-100">
        {sidebarItems && renderedSideBarItem(sidebarItems)}
      </Box>
    </Box>
  );
};
