import { Box, Typography } from '@mui/material';
import TextEditor from 'src/shared/components/text-editor/TextEditor';

interface TextEditorWrapperProps {
  onChange: (value: string) => void;
  value: string;
  required;
  label: string;
  placeholder: string;
}

const TextEditorWrapper = ({
  onChange,
  label,
  placeholder,
  value,
  required
}: TextEditorWrapperProps) => {
  // Constants
  const modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [
          { list: 'ordered' },
          { list: 'bullet' },
          { indent: '-1' },
          { indent: '+1' }
        ],
        ['link'],
        [{ color: [] }, { background: [] }],
        ['clean']
      ]
    }
  };

  const handleTextChange = (value: string) => {
    const isEmptyOrTagsOnly =
      !value.trim() || /^<p><br><\/p>$/.test(value.trim());
    onChange(isEmptyOrTagsOnly ? '' : value);
  };

  return (
    <Box className="textFields">
      <Typography variant="body2" sx={{ mb: '6px' }} align="left">
        <Box className="d-flex">
          <Box>
            {label}
            {required && <span style={{ color: '#E73A35' }}>*</span>}
          </Box>
        </Box>
      </Typography>
      <Box className="textEditor">
        <TextEditor
          onChangeText={handleTextChange}
          textEditorHeight="150px"
          textEditorControls={modules}
          placeholder={placeholder}
          value={value}
          required={required}
        />
      </Box>
    </Box>
  );
};

export default TextEditorWrapper;
