import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  addCompanyAPI,
  findAllCompaniesAPI,
  toggleCompanyStatusAPI,
  uploadDocumentAPI,
  downloadDocumentAPI,
  updateProfilePicAPI,
  editCompanyAPI
} from '../apis/CompanyApi';
import { OrganizationData, PermissionData } from '../models';
import { RootState } from 'src/store/reducer';
import {
  initalPaginationData,
  Pagination,
  PaginationResponseDto
} from 'src/core/model/pagination.dto';
import { DocumentType } from '../../../shared/models';

interface OrganizationState {
  companiesData: PaginationResponseDto<OrganizationData>;
  currentCompanyData: OrganizationData;
  //todo create Document model after refactor backend API
  companyLogo: DocumentType;
  loadingOrganizationData: boolean;
  successMessage: string;
  isError: boolean;
  errorMessage: string;
  permissionData: PermissionData;
  loadingPermissionDetails: boolean;
  loadingUpdatePermission: boolean;
  isCompanyDataUpdated: boolean;
}

const initialState: OrganizationState = {
  companiesData: initalPaginationData,
  currentCompanyData: null,
  companyLogo: null,
  loadingOrganizationData: false,
  successMessage: '',
  isError: false,
  errorMessage: '',
  permissionData: null,
  loadingPermissionDetails: false,
  loadingUpdatePermission: false,
  isCompanyDataUpdated: false
};
export const selectOrganizationState = ({ organizationsData }: RootState) => ({
  companiesData: organizationsData?.companiesData,
  currentCompanyData: organizationsData?.currentCompanyData,
  isCompanyDataUpdated: organizationsData?.isCompanyDataUpdated,
  companyLogo: organizationsData?.companyLogo,
  loadingOrganizationData: organizationsData?.loadingOrganizationData,
  isError: organizationsData?.isError,
  errorMessage: organizationsData?.errorMessage,
  permissionData: organizationsData?.permissionData,
  loadingPermissionDetails: organizationsData?.loadingPermissionDetails,
  loadingUpdatePermission: organizationsData?.loadingUpdatePermission
});

export const findAllCompanies = createAsyncThunk(
  'companiesData/findAllCompanies',
  async (payload: Pagination, { rejectWithValue }) => {
    try {
      const response = await findAllCompaniesAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const saveCompany = createAsyncThunk(
  'companiesData/saveCompany',
  async (payload: object, { rejectWithValue }) => {
    try {
      const response = await addCompanyAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editCompany = createAsyncThunk(
  'companiesData/editCompany',
  async (
    payload: { id: string; editCompanyData: object },
    { rejectWithValue }
  ) => {
    try {
      const response = await editCompanyAPI(
        payload?.editCompanyData,
        payload?.id
      );
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const addCompanyLogo = createAsyncThunk(
  'companiesData/addCompanyLogo',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await uploadDocumentAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const toggleCompanyStatus = createAsyncThunk(
  'companiesData/toggleCompanyStatus',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await toggleCompanyStatusAPI(id);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCompanyLogo = createAsyncThunk(
  'companiesData/getCompanyLogo',
  async (payload: string, { rejectWithValue }) => {
    try {
      const logoUrl = await downloadDocumentAPI(payload);
      const document: DocumentType = { id: payload, blobUrl: logoUrl };
      return document;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateCompanyLogo = createAsyncThunk(
  'companiesData/updateCompanyLogo',
  async (payload: any, { rejectWithValue }) => {
    try {
      const response = await updateProfilePicAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const organizationSlice = createSlice({
  name: 'companiesData',
  initialState,
  reducers: {
    setIsCompanyDataUpdated: (state: any, action) => {
      state.isCompanyDataUpdated = action.payload;
    },

    //remove below code if not used.
    setIsError: (state: any, action) => {
      state.isError = action.payload;
      if (!action.payload) {
        state.errorMessage = '';
      }
    },
    setCurrentCompanyDataEmpty: (state: any, action) => {
      if (!action.payload) {
        state.currentCompanyData = null;
        state.companyLogo = null;
      }
    },
    setCurrentCompanyData: (state: any, action) => {
      state.companyLogo = null;
      state.currentCompanyData = action.payload;
    },
    setSuccessMessageEmpty: (state: any, action) => {
      if (!action.payload) {
        state.successMessage = '';
      }
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(findAllCompanies.pending, (state) => {
        state.loadingOrganizationData = true;
      })
      .addCase(findAllCompanies.fulfilled, (state: any, action) => {
        state.loadingOrganizationData = false;
        state.isCompanyDataUpdated = false;
        if (action.payload) {
          let companiesData = action.payload;
          state.companiesData = companiesData;
        }
      })
      .addCase(findAllCompanies.rejected, (state, action: any) => {
        state.companiesData = [];
        state.loadingOrganizationData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(saveCompany.pending, (state) => {
        state.loadingOrganizationData = true;
        state.isCompanyDataUpdated = false;
      })
      .addCase(saveCompany.fulfilled, (state: any, action) => {
        state.loadingOrganizationData = false;
        state.isCompanyDataUpdated = true;
        state.currentCompanyData = action.payload.data;
        state.successMessage = action.payload.message;
      })
      .addCase(saveCompany.rejected, (state, action: any) => {
        state.loadingOrganizationData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(editCompany.pending, (state) => {
        state.loadingOrganizationData = true;
        state.isCompanyDataUpdated = false;
      })
      .addCase(editCompany.fulfilled, (state: any, action) => {
        state.loadingOrganizationData = false;
        state.isCompanyDataUpdated = true;
        state.currentCompanyData = action.payload.data;
        state.successMessage = action.payload.message;
      })
      .addCase(editCompany.rejected, (state, action: any) => {
        state.loadingOrganizationData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(addCompanyLogo.pending, (state) => {
        state.loadingOrganizationData = true;
      })
      .addCase(addCompanyLogo.fulfilled, (state: any, action) => {
        state.loadingOrganizationData = false;
        state.companyLogo = action.payload[0];
      })
      .addCase(addCompanyLogo.rejected, (state, action: any) => {
        state.loadingOrganizationData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(toggleCompanyStatus.pending, (state) => {
        state.loadingOrganizationData = true;
        state.isCompanyDataUpdated = false;
      })
      .addCase(toggleCompanyStatus.fulfilled, (state: any, action) => {
        state.loadingOrganizationData = false;

        // The toggle status isCompanyDataUpdated will trigger the goBack() from UserManagement.tsx and take you back to the previous page. The above two lines have been removed in order to prevent this.
        if (state.currentCompanyData) {
          state.currentCompanyData.isEnabled = action.payload.data.isEnabled;
        } else {
          state.companiesData.records = state.companiesData.records.map(
            (company) => {
              if (company.id === action?.payload?.data?.id) {
                company.isEnabled = action?.payload?.data?.isEnabled;
                return company;
              }
              return company;
            }
          );
        }
      })
      .addCase(toggleCompanyStatus.rejected, (state, action: any) => {
        state.loadingOrganizationData = false;
        state.isCompanyDataUpdated = true;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(getCompanyLogo.fulfilled, (state: any, action) => {
        state.loadingOrganizationData = false;
        const companyLogo: DocumentType = state.companyLogo;
        const returnCompanyLogo = action.payload;
        if (companyLogo) {
          companyLogo.blobUrl = returnCompanyLogo.blobUrl;
          state.companyLogo = companyLogo;
        } else {
          state.companyLogo = returnCompanyLogo;
        }
      })
      .addCase(getCompanyLogo.rejected, (state, action: any) => {
        state.loadingOrganizationData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      })
      .addCase(updateCompanyLogo.pending, (state) => {
        state.loadingOrganizationData = true;
      })
      .addCase(updateCompanyLogo.fulfilled, (state: any, action) => {
        state.loadingOrganizationData = false;
        state.currentCompanyData.profileId = action?.payload?.data?.id;
      })
      .addCase(updateCompanyLogo.rejected, (state, action: any) => {
        state.loadingOrganizationData = false;
        state.isError = true;
        state.errorMessage = action?.payload?.message
          ? action?.payload?.message
          : '';
      });
  }
});

export const {
  setIsError,
  setIsCompanyDataUpdated,
  setCurrentCompanyDataEmpty,
  setSuccessMessageEmpty,
  setCurrentCompanyData
} = organizationSlice.actions;
export default organizationSlice.reducer;
