import { Box } from '@mui/material';
import { StatusOption } from '../../components';
import React, { useRef, useState } from 'react';
import { ExpandMore } from '@mui/icons-material';

type IProps = {
  readonly options: StatusOption[] | undefined;
  readonly label?: string;
  readonly selectedVal: string | null;
  readonly handleChange: (data: StatusOption) => void;
  readonly disabled?: boolean;
  readonly className?: string;
};

export const Dropdown = ({
  options,
  label,
  selectedVal,
  handleChange,
  disabled,
  className
}: IProps) => {
  //const
  const inputRef = useRef<null | HTMLDivElement>(null);

  //state variables
  const [selectedStatus, setSelectedStatus] = useState(selectedVal);
  const [isOpen, setIsOpen] = useState(false);

  React.useEffect(() => {
    setSelectedStatus(selectedVal);
    const toggle = (event: MouseEvent) => {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };
    document.body.addEventListener('click', toggle);
    return () => {
      document.body.removeEventListener('click', toggle);
    };
  }, [selectedVal]);

  //function
  const selectOption = (option: StatusOption) => {
    setSelectedStatus(option.value);
    handleChange(option);
    setIsOpen((isOpen) => !isOpen);
  };

  return (
    <div
      className={`listDropdown ${className} ${isOpen ? 'open' : ''} ${
        disabled ? 'disabled' : ''
      }`}
      onClick={() => setIsOpen(!isOpen)}
      ref={inputRef}
    >
      <div className="width-per-182">
        <Box className="flex-basic-start ">
          {label && <Box>{label}:</Box>}
          <Box sx={{ ml: 2, mr: 4 }} className={`selected-value`}>
            <Box className="search-input">{selectedStatus}</Box>
          </Box>
        </Box>
        <ExpandMore
          className={`icon-arrow_dropdown arrow ${
            isOpen ? 'icon-rotate-180' : ''
          }`}
        />
        {/* <span
          className={`icon-arrow_dropdown arrow ${isOpen ? 'icon-rotate-180' : ''}`}
        ></span> */}
      </div>

      <div className={`options ${isOpen ? 'open' : ''}`}>
        {options?.map((option: StatusOption, index: number) => {
          return (
            <div
              key={option.id}
              className={`option ${
                option.value === selectedVal ? 'selected' : ''
              }`}
              onClick={() => selectOption(option)}
            >
              {option.value}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Dropdown;
