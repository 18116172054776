import { AnyAction, combineReducers } from '@reduxjs/toolkit';

import organizationReducer from 'src/modules/Organization/services/Company.service';
import adminUserDataReducer from 'src/modules/AdminManagement/services/adminManagement.service';
import userReducer from 'src/auth/login/services/userSlice';
import storage from 'redux-persist/lib/storage';
import singleIssuanceReducer from 'src/modules/Dashboard/Tabs/SingleIssuance/services/singleIssuance.service';
import bulkIssuanceReducer from 'src/modules/Dashboard/Tabs/BulkIssuance/services/BulkIssuance.service';
import agentDataReducer from 'src/modules/Agent/services/Agent.service';
import productDataReducer from 'src/modules/Product/services/Product.service';
import viewIssuanceReducer from 'src/modules/Dashboard/Tabs/ViewPolicy/services/ViewIssuance.service';

const combinedReducer = combineReducers({
  user: userReducer,
  organizationsData: organizationReducer,
  userData: userReducer,
  adminUserData: adminUserDataReducer,
  singleIssuanceData: singleIssuanceReducer,
  bulkIssuanceData: bulkIssuanceReducer,
  agentData: agentDataReducer,
  productData: productDataReducer,
  viewIssuanceData: viewIssuanceReducer
});

export type RootState = ReturnType<typeof combinedReducer>;

const rootReducer = (state: RootState, action: AnyAction) => {
  if (action.type.indexOf('/clearResults') > -1) {
    // this applies to all keys defined in persistConfig(s)
    storage.removeItem('persist:root');
    state = {} as RootState;
  }
  if (action.type === 'user/logout') {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export default rootReducer;
