import {
  SendPolicyPolicyPayload,
  ViewQuotationPayload
} from 'src/modules/Dashboard/model';
import { SINGLE_ISSUANCE_API } from 'src/shared/constants/constants';
import { apiDelete, apiGet, apiPost } from 'src/shared/utils/api-request';

export const saveSingleIssuanceDetailsAPI = async (payload: any) => {
  const res = await apiPost(`${SINGLE_ISSUANCE_API.SAVE_ISSUANCE}`, payload);
  return res;
};

export const deleteSingleIssuanceDetailsAPI = async (payload: {
  referenceId: string;
}) => {
  const res = await apiDelete(
    `${SINGLE_ISSUANCE_API.ISSUANCE}/${payload?.referenceId}`
  );
  return res;
};

export const getAgentListAPI = async (payload) => {
  const res = await apiGet(
    `${SINGLE_ISSUANCE_API.GET_AGENTS}?page=${payload.page ?? 1}&limit=${
      payload.limit ?? 1000
    }`
  );
  return res;
};

export const getInsurerListAPI = async (payload) => {
  const res = await apiGet(
    `${SINGLE_ISSUANCE_API.GET_INSURER}`
  );
  return res;
};

export const sendQuoteAPI = async (payload) => {
  const res = await apiPost(`${SINGLE_ISSUANCE_API.SEND_QUOTATION}`, payload);
  return res;
};

export const generatePolicyAPI = async (payload) => {
  const res = await apiPost(`${SINGLE_ISSUANCE_API.GENERATE_POLICY}`, payload);
  return res;
};

export const saveQuotationDetailsAPI = async (payload: any) => {
  const res = await apiPost(`${SINGLE_ISSUANCE_API.SAVE_QUOTATION}`, payload);
  return res;
};

export const deletePassengerAPI = async (payload: any) => {
  const res = await apiDelete(
    `${SINGLE_ISSUANCE_API.DELETE_PASSENGER}/${payload.passengerId}`
  );
  return res;
};

export const viewQuotationAPI = async (payload: ViewQuotationPayload) => {
  const res = await apiPost(`${SINGLE_ISSUANCE_API.VIEW_QUOTATION}`, payload);
  return res;
};

export const sendPolicyAPI = async (payload: SendPolicyPolicyPayload) => {
  const res = await apiPost(`${SINGLE_ISSUANCE_API.SNED_POLICY}`, payload);
  return res;
};
