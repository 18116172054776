import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'src/store/reducer';
import {
  initalPaginationData,
  Pagination,
  PaginationResponseDto
} from 'src/core/model/pagination.dto';
import { ViewPolicyTableData } from 'src/modules/Dashboard/model';
import ViewIssuance from '../ViewIssuance';
import { getViewIssuanceTravelerTableAPI } from '../api/ViewIssuanceApi';

export interface ViewIssuanceState {
  isError: boolean;
  isSuccess: boolean;
  successMsg: string;
  errorMsg: string;
  loading: boolean;
  tableLoading: boolean;
  viewTravelerData: PaginationResponseDto<ViewPolicyTableData>;
}

const initialState: ViewIssuanceState = {
  isError: false,
  isSuccess: true,
  successMsg: '',
  errorMsg: '',
  loading: false,
  tableLoading: false,
  viewTravelerData: initalPaginationData
};

export const selectorViewIssuanceState = ({ viewIssuanceData }: RootState) => ({
  isError: viewIssuanceData?.isError,
  isSuccess: viewIssuanceData?.isSuccess,
  successMsg: viewIssuanceData?.successMsg,
  errorMsg: viewIssuanceData?.errorMsg,
  loading: viewIssuanceData?.loading,
  tableLoading: viewIssuanceData?.tableLoading,
  viewTravelerData: viewIssuanceData?.viewTravelerData
});

// services
export const getViewIssuanceTraveler = createAsyncThunk(
  'viewIssuanceData/getViewIssuanceTraveler',
  async (payload: Pagination, { rejectWithValue }) => {
    try {
      const response = await getViewIssuanceTravelerTableAPI(payload);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const ViewIssuanceSlice = createSlice({
  name: 'ViewIssuanceData',
  initialState,
  reducers: {
    setLoading: (state: ViewIssuanceState, action) => {
      state.loading = action?.payload;
    },
    setIsError: (state: ViewIssuanceState, action) => {
      state.isError = action.payload;
    },
    setErrorMsg: (state: ViewIssuanceState) => {
      state.errorMsg = '';
    },
    setIsSuccess: (state: ViewIssuanceState, action) => {
      state.successMsg = action.payload;
    },
    resetViewPolicyTableData: (state) => {
      const resetData = initalPaginationData;
      state.viewTravelerData = resetData;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getViewIssuanceTraveler.pending, (state: ViewIssuanceState) => {
        state.tableLoading = true;
      })
      .addCase(
        getViewIssuanceTraveler.fulfilled,
        (state: ViewIssuanceState, action) => {
          state.tableLoading = false;

          if (action.payload) {
            state.viewTravelerData = action?.payload;
          }
        }
      )
      .addCase(
        getViewIssuanceTraveler.rejected,
        (state: ViewIssuanceState, action: any) => {
          state.tableLoading = false;
          state.isError = true;
          state.errorMsg = action?.payload?.message
            ? action?.payload?.message
            : '';
        }
      );
  }
});

export const {
  setErrorMsg,
  setIsError,
  setIsSuccess,
  setLoading,
  resetViewPolicyTableData
} = ViewIssuanceSlice.actions;

export default ViewIssuanceSlice.reducer;
