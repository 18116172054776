import { Pagination } from 'src/core/model/pagination.dto';
import {
  IssuanceType,
  SINGLE_ISSUANCE_API
} from 'src/shared/constants/constants';
import {
  ApiResponse,
  Config,
  apiDelete,
  apiGet,
  apiPatch,
  apiPost,
  apiPut
} from 'src/shared/utils/api-request';

export const getSingleIssuanceDataAPI = async (payload: Pagination) => {
  const res = await apiGet(
    `${SINGLE_ISSUANCE_API.GET_SINGLE_ISSUANCE}?page=${payload.page}&limit=${
      payload.limit
    }${(payload.search && `&search=${payload.search}`) || ''}${
      (payload.active && `&active=${payload.active}`) || ''
    }${(payload.status && `&status=${payload.status || ''}`) || ''}${
      (payload.IssuanceType && `&issuanceType=${IssuanceType.SINGLE}`) || ''
    }`
  );
  return res.data;
};
